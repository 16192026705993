import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  Button,
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
} from 'wix-ui-tpa/cssVars';
import { ActionBlockerAppearance } from '../ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../ActionBlocker/PreviewActionBlockerContainer';

import { classes, st } from './ConfirmDialog.st.css';
import { confirmDialogDataHooks } from './confirmDialogDataHooks';
import { IModalProps, Modal } from '../Modal';

export interface IConfirmDialogProps extends IModalProps {
  onLeave(): void;
  onCancel(): void;
  title: string;
  description: string;
  cancelLabel: string;
  confirmLabel: string;
  dataHook: string;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  title,
  description,
  confirmLabel,
  cancelLabel,
  opened,
  onClose,
  dataHook,
  ...props
}) => {
  const { isMobile } = useEnvironment();
  if (!opened) {
    return null;
  }
  return (
    <Modal opened={opened} onClose={onClose} dataHook={dataHook} key={dataHook}>
      <div
        className={st(classes.root, {
          mobile: isMobile,
        })}
        data-hook={confirmDialogDataHooks.main()}
      >
        <h1 className={classes.title} data-hook="a-123">
          {title}
        </h1>
        <p className={classes.description}>{description}</p>

        <div className={classes.controls}>
          <Button
            disabled={false}
            className={classes.cancel}
            data-hook={confirmDialogDataHooks.cancelBtn()}
            priority={ButtonPRIORITY.basicSecondary}
            size={ButtonSIZE.medium}
            onClick={props.onCancel}
          >
            {cancelLabel}
          </Button>
          <PreviewActionBlockerContainer
            appearance={ActionBlockerAppearance.PopoverAppearance}
          >
            <Button
              disabled={false}
              className={classes.cta}
              data-hook={confirmDialogDataHooks.completeBtn()}
              priority={ButtonPRIORITY.primary}
              size={ButtonSIZE.medium}
              onClick={props.onLeave}
            >
              {confirmLabel}
            </Button>
          </PreviewActionBlockerContainer>
        </div>
      </div>
    </Modal>
  );
};

ConfirmDialog.displayName = 'ConfirmDialog';
