import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useLocation } from '../../../contexts/Location/LocationContext';
import { Box, BoxProps } from '../../Box';
import { Text } from 'wix-ui-tpa/cssVars';
import { SocialShare } from '../../../components/ChallengesPage/Widget/components/SocialShare';
import { useChallengeData } from '../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useUser } from '../../../contexts/User/UserContext';

export const ShareProgramSection: React.FC<{
  marginTop?: BoxProps['marginTop'];
}> = (props) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { location: { url = '' } = {} } = useLocation();
  const { challengeData } = useChallengeData();
  const { participant } = useUser();
  const programName = challengeData.challenge.settings.description.title;

  if (!experiments.enabled('specs.program.EnableSocialShareInCompleteModal')) {
    return null;
  }

  return (
    <Box
      tag="section"
      direction={isMobile ? 'column' : 'row'}
      marginTop={props.marginTop}
      justifyContent="center"
      alignItems="center"
    >
      <Text>{t('live.challenges-page.modal.challenge-completed.share')}</Text>
      <SocialShare
        shareTitle={encodeURIComponent(
          t('live.challenges-page.modal.challenge-completed.content', {
            programName,
            performance: participant.performance + '%',
          }),
        )}
        shareUrl={url}
        originalUrl={url}
      />
    </Box>
  );
};
