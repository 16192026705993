import React from 'react';
import { ConfirmDialog } from '../ConfirmDialog';
import { ButtonNames } from '../../contexts/main/biInterfaces';
import { UserContext } from '../../contexts/User/UserContext';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { FedopsInteraction } from '../../config/fedopsInteraction';
import { useMonitoring } from '../../contexts/Monitoring/MonitoringContext';
import { leaveChallengeModalDataHooks } from './leaveChallengeModalDataHooks';

export interface ILeaveChallengeModal {
  isOpen: boolean;
  participantId: string;
  challengeId?: string;
  onClose?(): void;
  onLeave?(): void;
}

export const LeaveChallengeModal: React.FC<ILeaveChallengeModal> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);
  const userCtx = React.useContext(UserContext);
  const bi = useBi();
  const { startInteraction } = useMonitoring();
  const { t } = useTranslation();

  const beforeLeaveProgram = React.useCallback(async () => {
    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.LeaveTheChallengeAtModal,
      }),
    );
    await startInteraction(
      FedopsInteraction.LeaveFromProgram,
      props.challengeId,
    );
  }, []);

  const afterLeaveProgram = React.useCallback(() => {
    setIsOpen(false);
    props.onClose && props.onClose();
    props.onLeave && props.onLeave();
  }, []);

  const onLeaveProgram = React.useCallback(async () => {
    await beforeLeaveProgram();
    await userCtx.leaveTheChallenge(props.participantId, props.challengeId);
    afterLeaveProgram();
  }, [props.participantId, props.challengeId]);

  const onCloseLeaveModal = React.useCallback(async () => {
    setIsOpen(false);
    props.onClose();

    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.CancelLeaveTheChallengeModal,
      }),
    );
  }, []);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <ConfirmDialog
      onLeave={onLeaveProgram}
      onCancel={onCloseLeaveModal}
      title={t('leave-the-challenge.title')}
      description={t('leave-the-challenge.description')}
      cancelLabel={t('leave-the-challenge.cancel')}
      confirmLabel={t('leave-the-challenge.cta')}
      opened={isOpen}
      onClose={onCloseLeaveModal}
      dataHook={leaveChallengeModalDataHooks.main()}
    />
  );
};
